<template>
  <div class="card">
    <h1 class="tw-text-xl tw-font-bold tw-mb-8">Edit Salon Service</h1>
    <div>
      <SalonServiceForm
        @submit="submitSalonService"
        @upload="upload"
        :initial-data="detail"
      />
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import SalonServiceForm from "./Form";
import {
  useSalonServiceDetail,
  useSalonServiceForm,
} from "@/composables/salon_service";
import { onBeforeMount } from "vue";

const router = useRouter();
const route = useRoute();
const { detail, getDetail } = useSalonServiceDetail();
const { updateImage } = useSalonServiceForm();
const { update } = useSalonServiceForm();

const submitSalonService = async (data) => {
  await update(route.params.id, data);
  await router.push({ name: "salon-services" });
};

const upload = async (data) => {
  await updateImage(route.params.id, data);
  await getDetail(route.params.id);
};

onBeforeMount(async () => {
  await getDetail(route.params.id);
});
</script>
